<template>
  <div class="managersWrap">
    <div class="searchWrap">
      <span class="label"> 管理员电话： </span>
      <el-input
        v-model="searchValue"
        clearable
        placeholder="请输入手机号"
      ></el-input>
      <el-button type="primary" icon="el-icon-search" @click="search"
        >搜索</el-button
      >
    </div>
    <div>
      <el-button
        type="primary"
        icon="el-icon-plus"
        class="addManager"
        @click="$router.push('/userManage/managers/add')"
      >
        添加管理员
      </el-button>
    </div>
    <el-table
      :data="tableDataInfo.result"
      style="width: 100%"
      class="managersTable"
      border
    >
      <el-table-column
        v-for="c in tableColumns"
        :key="c.prop"
        :label="c.label"
        :width="c.width"
      >
        <template slot-scope="scope">
          <div v-if="c.prop.indexOf('time') > -1">
            {{ dateTransform(scope.row[c.prop]) }}
          </div>
          <div class="operations" v-else-if="c.prop == 'operations'">
            <el-button size="mini" @click="resetPassword(scope.row.id)"
              >重置密码
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleEdit(scope.row.id)"
              >编辑
            </el-button>
            <el-popconfirm
              title="确定删除吗？"
              @confirm="deleteManager([scope.row.id])"
            >
              <el-button size="mini" type="danger" slot="reference"
                >删除</el-button
              >
            </el-popconfirm>
          </div>
          <span style="margin-left: 10px" v-else>{{ scope.row[c.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-if="tableDataInfo.total > 8"
      :handleSizeChange="handleSizeChange"
      :handleCurrentPageChange="handleCurrentPageChange"
      :total="tableDataInfo.total"
    />
    <el-dialog title="重置密码" :visible.sync="resetPasswordDialogVis">
      <el-form
        :model="resetPwdForm"
        :rules="rules"
        ref="resetPwdForm"
        label-width="100px"
        class="resetPwdForm"
      >
        <el-form-item label="新密码" prop="password">
          <el-input
            type="password"
            v-model="resetPwdForm.password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>

        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            type="password"
            v-model="resetPwdForm.confirmPassword"
            placeholder="请输入确认密码"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="resetPasswordDialogVis = false">取 消</el-button>
        <el-button type="primary" @click="confirmResetPassword"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import Pagination from "@/components/Pagination";
export default {
  data() {
    return {
      tableColumns: [
        {
          label: "管理员名称",
          prop: "cn_name",
        },
        {
          label: "管理员电话",
          prop: "phone",
        },
        {
          label: "管理员账号",
          prop: "login_name",
        },
        {
          label: "创建时间",
          prop: "create_time",
        },
        {
          label: "更新时间",
          prop: "update_time",
        },
        {
          label: "操作",
          prop: "operations",
          width: "250",
        },
      ],
      tableDataInfo: {},
      resetPwdForm: {},
      searchValue: "",
      activeManagerId: "",
      resetPasswordDialogVis: false,
      rules: {
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
          {
            validator: this.confirmPwdValidator,
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    Pagination,
  },
  mounted() {
    this.getTableData({});
  },
  methods: {
    handleSizeChange(page_size) {
      this.getTableData({ page_size, page_num: 1 });
    },
    handleCurrentPageChange(page_num) {
      this.getTableData({ page_num });
    },
    deleteManager(ids) {
      this.$request.deleteManager({ ids }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }

        this.getTableData({});
      });
    },
    resetPassword(id) {
      this.activeManagerId = id;
      this.resetPasswordDialogVis = true;
    },
    confirmResetPassword() {
      this.$refs.resetPwdForm.validate((valid) => {
        const { password, confirmPassword } = this.resetPwdForm;
        if (!valid) {
          return false;
        }
        if (confirmPassword != password) {
          this.confirmPwdValidator();
          return;
        }
        this.$request
          .resetManagerPassword({
            id: this.activeManagerId,
            password: this.resetPwdForm.password,
          })
          .then((res) => {
            if (res.status != "success") {
              this.$message.error(res.msg);
              return;
            }
            this.resetPasswordDialogVis = false;
            this.getTableData({});
          });
      });
    },

    confirmPwdValidator(rule, value, callback) {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.resetPwdForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    },

    getTableData({ phone, status, page_num = 1, page_size = 8 }) {
      this.$request
        .queryManagers({ page_num, page_size, phone, status })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.tableDataInfo = res.data || {};
        });
    },
    handleEdit(id) {
      this.$router.push({
        path: "/userManage/managers/edit",
        query: {
          id,
        },
      });
    },
    search() {
      this.getTableData({
        phone: this.searchValue,
        status: this.selectedStatus,
      });
    },
    dateTransform(d) {
      return moment(d).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style lang='scss'>
.managersWrap {
  padding: 0 24px 24px;
  background-color: #fff;
  .searchWrap {
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 24px 0;
    align-items: center;
    .el-input {
      width: 200px !important;
    }
    .el-button {
      margin-left: 16px;
    }
  }
  .addManager {
    margin: 16px 0;
  }
  .managersTable {
    .el-button {
      margin-left: 10px !important;
    }
  }
}
</style>